import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import omit from 'lodash/omit'

import PATHS from 'routes/paths'

import { useGlobalContext } from 'context'

import RegisterWithUs from 'components/RegisterWithUs'
import { COLOR } from 'styles/tokens'
import { Button, H, Wrapper } from 'components/common'
import Layout from 'components/layout'
import SEO from 'components/seo'
import JobList from 'components/JobList'

import { getStore } from 'lib/storage/local-storage'

const Saved = ({ pageContext: { jobs } }) => {
  const { savedJobs, setSavedJobs } = useGlobalContext()
  const [hasOfflineSavedJobs, setHasOfflineSavedJobs] = useState(false)
  const [filteredJobs, setFilteredJobs] = useState([])

  useEffect(() => {
    const savedJobsFromLocalStore = getStore('ppSavedJobs')
    const savedJobsIds = Object.keys(savedJobsFromLocalStore)
    const offlineJobsIds = []

    const filtered = savedJobsIds.reduce((acc, id) => {
      const bullhornJob = jobs.find((job) => String(job.id) === id)

      // Find and filter out any 'offline' jobs
      if (!bullhornJob) {
        offlineJobsIds.push(id)
        return acc
      }

      return [...acc, bullhornJob]
    }, [])

    // Updates the list of jobs displayed
    setFilteredJobs(filtered)

    // We keep this set to true if it was true on page load
    if (offlineJobsIds.length) {
      setHasOfflineSavedJobs(true)
    }

    // Updates the savedJobs in local storage and context
    setSavedJobs(omit(savedJobsFromLocalStore, offlineJobsIds))
  }, [JSON.stringify(savedJobs)])

  const hasSavedJobs = filteredJobs?.length > 0

  return (
    <Layout>
      <SEO title="Saved jobs" />
      <Wrapper background={COLOR.GREY.DARK}>
        <Wrapper container>
          <H size="XL" color={COLOR.WHITE} centered>
            Saved jobs
          </H>
        </Wrapper>
      </Wrapper>
      <Wrapper background={COLOR.BACKGROUND.PALE}>
        <Wrapper container>
          {hasSavedJobs && <JobList jobs={filteredJobs} />}

          {hasOfflineSavedJobs && (
            <Wrapper centered margin={['L', 0, 0]}>
              <p>
                1 or more of your previously saved jobs is now closed.
                <br />
                You can search for more jobs <Link to={PATHS.JOBS}>here</Link>.
              </p>
            </Wrapper>
          )}

          {!hasOfflineSavedJobs && !hasSavedJobs && (
            <Wrapper centered margin={['L', 0, 0]}>
              <p>You don’t have any saved jobs at the moment.</p>
              <Button.Primary to={PATHS.JOBS} tag={Link} wide>
                Search jobs
              </Button.Primary>
            </Wrapper>
          )}
        </Wrapper>
      </Wrapper>
      <RegisterWithUs />
    </Layout>
  )
}

Saved.propTypes = {
  pageContext: PropTypes.shape({
    jobs: PropTypes.array,
  }).isRequired,
}

export default Saved
