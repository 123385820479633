import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import styled from 'styled-components'
// import { COLOR, FONT, GTR } from 'styles/tokens'
// import { brighten } from 'styles/helpers/color'

// import { Button } from 'components/common'

import heartAnimation from 'images/heart-animation.png'

import { useGlobalContext } from 'context'

const StyledHeart = styled.div`
  width: 75px;
  height: 75px;
  background: url('${heartAnimation}')
    no-repeat;
  background-position: 0 0;
  cursor: pointer;
  transition: background-position 0.5s steps(28);
  transition-duration: 0s;
  background-size: auto 75px;
  display: inline-block;

  ${({ $active }) =>
    $active &&
    `
    transition-duration: 0.5s;
    background-position: -2100px 0;
  `}
`

// const StyledButton = styled.div`
//   font-weight: ${FONT.WEIGHT.REGULAR};
//   font-size: ${FONT.SIZE.S};

//   svg {
//     transition: color 0.1s ease-in;
//     color: ${COLOR.ACCENT.PRIMARY};
//     margin-right: ${GTR.XS};
//   }

//   &:hover {
//     svg {
//       color: ${brighten(COLOR.ACCENT.PRIMARY, -0.2)};
//     }
//   }
// `

const SaveJob = ({ jobId }) => {
  const { savedJobs, setSavedJobs } = useGlobalContext()
  const isSaved = savedJobs[jobId]

  const toggle = () => {
    if (isSaved) {
      setSavedJobs(omit(savedJobs, jobId))
    } else {
      setSavedJobs({ ...savedJobs, [jobId]: 1 })
    }
  }

  return <StyledHeart $active={isSaved} onClick={toggle} />
}

SaveJob.propTypes = {
  jobId: PropTypes.number.isRequired,
}

export default SaveJob
