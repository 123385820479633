import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR, FONT } from 'styles/tokens'
import Bullet from 'components/Bullet'

const StyledP = styled.p`
  font-weight: ${FONT.WEIGHT.MEDIUM};
  margin-bottom: 0;

  ${({ $color }) => `color: ${$color}`}
`

const StyledItem = styled.span`
  white-space: nowrap;
`

const getBusinessSector = (job) => {
  return job.businessSectors.data[0]?.name
}

const JobDetails = ({ color, className, job }) => {
  const businessSector = getBusinessSector(job)
  const hasSalary = !!job.salary

  return (
    <StyledP $color={color} className={className}>
      {job.address.city}, {job.address.country} <Bullet /> {job.employmentType}
      {businessSector && (
        <>
          <Bullet /> <StyledItem>{businessSector}</StyledItem>
        </>
      )}
      {hasSalary && (
        <>
          <Bullet />
          <StyledItem>
            Up to{' '}
            {job.salary.toLocaleString('en-GB', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </StyledItem>
        </>
      )}
      <Bullet />
      <StyledItem>Ref: {job.id}</StyledItem>
    </StyledP>
  )
}

JobDetails.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  job: PropTypes.shape({
    id: PropTypes.number,
    salary: PropTypes.number,
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    employmentType: PropTypes.string,
  }).isRequired,
}

JobDetails.defaultProps = {
  className: '',
  color: COLOR.GREY.DARK,
}

export default JobDetails
