import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { screenMax } from 'styles/helpers/responsive'

import CurveWrapper from 'components/CurveWrapper'
import Image from 'components/Image'
import styled from 'styled-components'
import { COLOR, FONT, GTR } from 'styles/tokens'
import { Button, H, Grid } from 'components/common'

import PATHS from 'routes/paths'

const StyledP = styled.p`
  font-size: ${FONT.SIZE.L};
  margin-bottom: ${GTR.L};
`

const StyledImageGridItem = styled(Grid.Item)`
  ${screenMax.m`
    display: none;
  `}
`

const RegisterWithUs = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "man-on-bridge-looking-at-phone.png" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <CurveWrapper background={COLOR.ACCENT.PRIMARY} top={38}>
      <Grid container>
        <Grid.Item spanFromL={6} style={{ alignSelf: 'center' }}>
          <H size="L">Discover something different</H>
          <StyledP>We’ll keep you posted with all the latest roles</StyledP>
          <Button.White tag={Link} to={PATHS.REGISTER} wide stretchMobile>
            Register with us
          </Button.White>
        </Grid.Item>
        <StyledImageGridItem spanFromL={5} startColumnFromM={8}>
          <Image
            sizes={{ ...image.childImageSharp.fluid, aspectRatio: 3 / 2 }}
          />
        </StyledImageGridItem>
      </Grid>
    </CurveWrapper>
  )
}

export default RegisterWithUs
