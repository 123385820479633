import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

import JobDetails from 'components/JobDetails'
import SaveJob from 'components/SaveJob'
import { H, Button, Wrapper } from 'components/common'

import { BORDER, COLOR, GTR, FONT } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'

const StyledJobTitle = styled(H)`
  display: block;
  font-weight: ${FONT.WEIGHT.BOLD};
  font-size: ${FONT.SIZE.XL};
  margin-bottom: ${GTR.XS};

  ${screenMax.s`
    font-size: ${FONT.SIZE.L};
  `}
`

const StyledJobLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLOR.WHITE};
  padding: ${GTR.M} ${GTR.L};
  border-radius: ${BORDER.RADIUS.M};

  &:not(:last-of-type) {
    margin-bottom: ${GTR.XL};
  }

  ${screenMax.m`
    flex-direction: column;
    align-items: stretch;
  `}
`

const StyledButtonWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;

  ${screenMax.m`
    margin-top: ${GTR.S};
  `}

  ${screenMax.s`
    margin-top: 0;
    flex-direction: column;
  `}
`

const StyledJobButton = styled(Button.Primary)`
  white-space: nowrap;
`

const JobList = ({ jobs }) => {
  return (
    <ul>
      {jobs.map((job) => (
        <StyledListItem key={job.id}>
          <Wrapper>
            <StyledJobTitle>
              <StyledJobLink to={job.internalUrl}>{job.title}</StyledJobLink>
            </StyledJobTitle>
            <JobDetails job={job} />
          </Wrapper>
          <StyledButtonWrapper>
            <Wrapper
              margin={0}
              marginFromM={[0, 'XXS', 0, 0]}
              style={{ display: 'flex' }}
            >
              <SaveJob jobId={job.id} />
            </Wrapper>
            <StyledJobButton to={job.internalUrl} tag={Link} stretchMobile>
              View job
            </StyledJobButton>
          </StyledButtonWrapper>
        </StyledListItem>
      ))}
    </ul>
  )
}

JobList.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
}

JobList.defaultProps = {
  jobs: [],
}

export default JobList
